import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'John Grasty | Web Designer and Host', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to the place for quality, modern sites and made to order copywriting.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'John Grasty',
  subtitle: "I'm to help you on your next project.",
  cta: 'Find out more',
};

// ABOUT DATA
export const aboutData = {
  img: 'hero2.jpg',
  paragraphOne:
    'If you are looking for a fast, secure, custom website for your business, church, or whatever, you have come to the right place. With advances in web technology, you can have a custom site that meets your needs for far less time and money than one would expect even just a few years ago. We can work together to see what your needs are and come up with something to fit your budget and situation.',
  paragraphTwo:
    'Recently I have added copywriting to my repertoire, because through the years, I have done so much writing for my own sites and for others. Now it is time to open that up to others. My goal is to provide you with what you need---in any voice or style, with attention to detail, and valuing your unique situation. ',
  paragraphThree: 'Get started today with a new project!',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'scotia.jpg',
    title: 'Victory Baptist Church',
    info:
      'A custom church website built on a customized backend optimized for volunteers easily adding new information. The site focuses on being modern, responsive (it looks as good on mobile as on a PC), and fast.',
    info2: 'It is built as a secure static site using Gatsby and a custom CMS from Sanity.',
    url: 'https://www.scotiavictory.com',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'vrelec.jpg',
    title: 'Vrelec Publishing (Slovenia)',
    info:
      'This brochure type site is for a Slovene publishing house. It is built using Svelte to acheive a very lightweight and blazingly fast site to put their best foot forward right from the beginning.',
    info2: '',
    url: 'https://vrelec.si',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'raziskujmo.jpg',
    title: 'Raziskujmo krščanstvo',
    info:
      'Another Gatsby site, but this time for a lecture series on Youtube. Data management is kept simple in this case with just simple markdown files for each video.',
    info2: '',
    url: 'https://raziskujmo.vrelec.si/raziskujmo-krscanstvo',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Want more information or a quote? Great!',
  btn: 'Send me an email',
  email: 'john@grastytech.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'envelope',
      url: 'mailto:john@grastytech.com',
    },
    {
      id: nanoid(),
      name: 'facebook',
      url: 'https://www.facebook.com/GrastyWebDesign',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/johngrasty',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
